import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Axios from "axios";
import { connect } from "react-redux";
import { setCurrentUser } from "../../redux/user/userActions";
import { useHistory } from "react-router";
import CircularProgress from "@material-ui/core/CircularProgress";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="/">
        Capture
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Register = (props) => {
  const classes = useStyles();
  const { loginUser } = props;
  const history = useHistory();

  const [data, setData] = useState({
    email: "",
    password: "",
    password2: "",
    fname: "",
    lname: "",
  });
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const validateEmail = (email) => {
    if (
      email &&
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      return true;
    }
    setError("You have entered an invalid email address!");
    return false;
  };

  const validateNames = (data) => {
    if (data.fname && data.lname) {
      return true;
    }
    setError("You missed a name!");
    return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!validateNames(data)) {
      setLoading(false);
      return;
    }
    if (!validateEmail(data.email)) {
      setLoading(false);
      return;
    }
    if (data.password !== data.password2) {
      setError("Passwords do not match");
    }
    Axios.post("http://api.fixingfocus.com:5000/register", data)
      .then((resp) => {
        loginUser({
          jwt_token: resp.data.user_data.jwt_token,
          refresh_token: resp.data.user_data.refresh_token,
          username: resp.data.user_data.username,
          email: resp.data.user_data.email,
          fname: resp.data.user_data.fname,
          lname: resp.data.user_data.lname,
        });
        setLoading(false);
        history.push("/home");
      })
      .catch((err) => {
        console.log(err.response);
        const response = err.response;
        response.status === 401 && setError(response.data.msg);
        response.status >= 500 &&
          setError("Server error, try again in a minute");
        response.status > 401 &&
          response.status < 500 &&
          setError("Something weird happened...");
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    setError(null);
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Create an account!
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="fname"
            label="First Name"
            name="fname"
            onChange={handleChange}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="lname"
            label="Last Name"
            name="lname"
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password2"
            label="Verify Password"
            type="password"
            id="password-verify"
            onChange={handleChange}
          />
          {error && (
            <Box pt={1} style={{ textAlign: "center" }}>
              <Typography variant="body2" color="error">
                {error}
              </Typography>
            </Box>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Complete
            {isLoading && <CircularProgress />}
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loginUser: (userObj) => dispatch(setCurrentUser(userObj)),
});

export default connect(null, mapDispatchToProps)(Register);
