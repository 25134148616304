import { Box, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Paper, Typography } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import ChallengePreview from '../ChallengePreview/ChallengePreview';

const ChallengeBrowser = props => {
  const {match: {params: {level}}, user} = props;

  const [ filters, setFilters ] = useState({mobilePhone: false, kitLens: false, specialLens: false, advancedEquipment: false});
  const [ loading, setLoading ] = useState(false);
  const [ challenges, setChallenges ] = useState(null);
  const [ error, setError ] = useState(null);


  const handleChange = (event) => {
    setFilters({...filters, [event.target.name]: event.target.checked});
  };

  // mock data
  const data = {
    copy: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  }

  const getChallenges = e => {
    setLoading(true);
    const config = {
      headers: { 
        Authorization: `Bearer ${user.jwt_token}`,
      }
    };
    Axios.get(`/challenges/${level}`, config)
    .then(resp => {
      setLoading(false);
      if (resp.data.msg === true) { 
        setChallenges(resp.data.challenges)
      }
    })
    .catch(resp => {
      console.error(resp);
      setError(resp);
      setLoading(false);
    });
  };

  // Load data on initial load
  useEffect(() => getChallenges(), []) 

  return(
    <Box pl={4} pr={4} pt={2} >
      <Typography variant="h4">{level.toUpperCase()}</Typography>
      <Box mt={2} width="80%" textAlign="left">
        <Typography variant="body1">{data.copy}</Typography>
      </Box>
      <br />
      <Divider />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Paper style={{backgroundColor: '#F0F0F0'}}>
            <Box p={2}>
              <Typography variant="h6">FILTERS</Typography>
              <Box mt={2}>
                <FormControl component="fieldset">
                  <Box pl={1}>
                    <FormLabel component="legend"><Typography variant="body2">COMPATIBILITY</Typography></FormLabel>
                  </Box>
                  <Box pl={4}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={filters.mobilePhone} onChange={handleChange} name="mobilePhone" />}
                        label="Mobile Phone"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={filters.kitLens} onChange={handleChange} name="kitLens" />}
                        label="Kit Lens"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={filters.specialLens} onChange={handleChange} name="specialLens" />}
                        label="Special Lens"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={filters.advancedEquipment} onChange={handleChange} name="advancedEquipment" />}
                        label="Advanced Equipment"
                      />
                    </FormGroup>
                  </Box>
                </FormControl>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={9}>
          {challenges && challenges.map(item => (
            <Accordion elevation={3} style={{marginBottom: '8px'}} square={false} key={item.name}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel ${item.name} content`}
                id={`panel-${item.name}`}
              >
                <Box>
                  <Box p={2}>
                    <Typography variant="h6">{item.name.toUpperCase()}</Typography>
                  </Box>
                </Box>
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                <ChallengePreview item={item} />
              </AccordionDetails>
            </Accordion>
            
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = store => {
  return {
    user: store.user.currentUser
  }
};

export default connect(mapStateToProps)(ChallengeBrowser);