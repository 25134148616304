import { Box, Card, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Typography } from '@material-ui/core';
import React from 'react';

const Challenge = ({data, handleChange}) => {
  return(
    <Box p={4} width="50%" m="auto">
      <Card elevation={3} style={{display: 'flex', padding: '16px'}}>
        <Grid container>
          <Grid item xs={5}>
            <Box>
              <Typography variant="h6">Team</Typography>
              <Typography variant="caption">Team Participating in Challenge</Typography>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Box>
              <FormControl>
                <InputLabel id="select-label">Choose Team</InputLabel>
                <Select
                  labelId="select-label"
                  id="select"
                  name="team"
                  value={data.team}
                  onChange={handleChange}
                  style={{width: 300}}
                >
                  {/* These items will be pulled from backend */}
                  <MenuItem value={"Team1"}>Team1</MenuItem>
                  <MenuItem value={"Team2"}>Team2</MenuItem>
                  <MenuItem value={null}>None</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Card>
      <br />
      <Card elevation={3} style={{display: 'flex', padding: '16px'}}>
        <Grid container>
          <Grid item xs={5}>
            <Box>
              <Typography variant="h6">Challenge</Typography>
              <Typography variant="caption">Current Challenge</Typography>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Box>
              <FormControl>
                <InputLabel id="select-label2">Choose Challenge</InputLabel>
                <Select
                  labelId="select-label2"
                  id="select2"
                  name="challenge"
                  value={data.challenge}
                  onChange={handleChange}
                  style={{width: 300}}
                >
                  {/* These items will be pulled from backend */}
                  <MenuItem value={"B&W"}>B&W</MenuItem>
                  <MenuItem value={"Street"}>Street</MenuItem>
                  <MenuItem value={null}>None</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
}

export default Challenge;