import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';

const ImageSelecter = ({handleFileChange, file}) => {
    return (
      <Box p={4} textAlign="center">
        <Button
          variant="contained"
          component="label"
        >   
          Upload File
          <input
            name="file"
            type="file"
            hidden
            onChange={handleFileChange}
          />
        </Button>
        <Typography variant="body2">{file?.name}</Typography>
      </Box>
        
    );
};

export default ImageSelecter;