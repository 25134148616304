import React from "react";
import { Route, Switch } from "react-router-dom";
import ChallengeBrowser from "../ChallengeBrowser/ChallengeBrowser";
import ChallengesPage from "../Challenges/Challenges";
import Home from "../Home/Home";
import Login from "../Login/Login";
import Logout from "../Logout/Logout";
import Register from "../Register/Register";
import Root from "../Root/Root";
import UploadForm from "../Upload/Upload";
import UserSettings from "../UserSettings/UserSettings";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route component={Root} path="/" exact />
        <Route component={Home} path="/home" exact />

        <Route component={Login} path="/login" exact />
        <Route component={Logout} path="/logout" exact />
        <Route component={Register} path="/register" exact />

        <Route component={UploadForm} path="/upload" exact />

        <Route component={ChallengesPage} path="/challenges" exact />
        <Route component={ChallengeBrowser} path="/challenges/:level" />

        <Route component={UserSettings} path="/user/settings" />
      </Switch>
    </div>
  );
}

export default App;
