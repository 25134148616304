import { Box, Card, Grid, Typography } from '@material-ui/core';
import React from 'react';

const Post = ({ fileURL, data }) => {
  return (
    <Box mb={2}>
      <Card style={{backgroundColor: '#F0F0F0'}}>
        <Grid container>
          <Grid item xs={6} >
            <Box p={2}>
              <img src={fileURL} alt="your upload" style={{width: "100%", height: "100%"}}/>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box p={2}>
              <Typography variant="h4">Challenge: {data.challenge}</Typography>
              <Typography variant="body1">{data.caption}</Typography>
              <Typography variant="caption">My intentions: {data.intentions}</Typography>
              <br />
              <Typography variant="caption">Setup notes: {data.setupNotes}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default Post;