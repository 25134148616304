import { Box, Card, Typography } from '@material-ui/core';
import React from 'react';

const ImageLayout = props => {
  return (
    <Box>
      <Typography variant="h6">Select a preview layout:</Typography> 
      <Card style={{backgroundColor: '#F0F0F0'}}>
        Not implemented yet :/
      </Card>
    </Box>
  );
};

export default ImageLayout;