import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import Post from '../UploadStepper/Review/PostReview';

const MyPosts = props => {
  const { user } = props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [posts, setPosts] = useState([]);

  console.log(posts);

  const convertS3Url = s3Url => {
    let name = s3Url.split('/').pop();
    let bucketName = 'capture-dev-images';
    let region = 's3-us-west-1'

    return `https://${bucketName}.${region}.amazonaws.com/${name}`;

  };

  const getPosts = e => {
    setLoading(true);
    const config = {
      headers: { 
        Authorization: `Bearer ${user.jwt_token}`,
      }
    };
    Axios.get('/posts', config)
    .then(resp => {
      setLoading(false);
      if (resp.data.msg === true) { 
        setPosts(resp.data.posts)
      }
    })
    .catch(resp => {
      console.error(resp);
      setError(resp);
      setLoading(false);
    });
  };

  // Load data on initial load
  useEffect(() => getPosts(), []) 


  return(
    <Box>
      {error && <Typography variant="body1" color="error">Error loading posts...</Typography>}
      {loading && <Typography variant="body1">Loading posts...</Typography>}
      {
        posts.map(post => (
          <Post 
            fileURL={convertS3Url(post.image_url)} 
            data={{challenge: "B&W", intentions: post.intention, caption: post.text, setupNotes: post.notes}}
          />
        ))
      }
    </Box>
  );
};

const mapStateToProps = store => {
  return {
    user: store.user.currentUser
  }
};

export default connect(mapStateToProps)(MyPosts);