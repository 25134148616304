import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  '@keyframes rotate': {
    from: {transform: 'rotate(0deg)'},
    to: {transform: 'rotate(360deg)'}
  },
  spinner: {
    animationName: '$rotate',
    animationIterationCount: 'infinite',
    animationDuration: '10s',
    animationTimingFunction: 'linear'
  }
}));

const Root = props => {
  const classes = useStyles();

  return(
      <Box style={{backgroundColor: '#BBBBBB', height: '100vh', width: '100vw'}} >
        <Box pt={10}></Box>
        <Box ml='auto' mr='auto' style={{backgroundImage: 'url(Frame.svg)', backgroundRepeat: 'no-repeat', width: 400, height: 400}}> 
          <Box m='auto' className={classes.spinner} style={{backgroundImage: 'url(Center.svg)', backgroundRepeat: 'no-repeat', width: 200, height: 200, position: 'relative', top: '55px'}}>
          </Box>
        </Box>
          <Box textAlign="center">
              <Typography variant="h1">FixingFocus</Typography>
              <Typography variant="h5">The web app designed to encourage personal photography growth in a team setting</Typography>
          </Box>  
          <Box textAlign="center">
              <Typography variant="h5">Alpha release: Summer 2021</Typography>
          </Box>
          
          {/* <Box textAlign="center" mt={12}>
              <Typography variant="h6">Now in Alpha. Have an alpha code? Click <a href="/login">here</a> to get started!</Typography>
              <Typography variant="h6">Already have an account? Login <a href="/login">here</a>!</Typography>
          </Box>  */}
      </Box>
  );
};

export default Root;