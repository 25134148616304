import { Box, Button, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles(theme => ({
  h6Header: {
    letterSpacing: '2px',
  },
  leftArrow: {
    position: 'absolute',
    top: '50%',
    right: '46%',
  },
  rightArrow: {
    position: 'absolute',
    top: '50%',
    right: '0%',
  },
}));

const ChallengePreview = ({ item }) => {

  const { description, goals, example_links, example_images } = item;

  const classes = useStyles();

  const [ linkUrlIndex, setLinkUrlIndex ] = useState(0);

  const imageList = example_images?.split(",");

  const convertS3Url = s3Url => {
    let name = s3Url.split('/').pop();
    let bucketName = 'capture-demo-images';
    let region = 's3-us-west-1'

    return `https://${bucketName}.${region}.amazonaws.com/${name}`;
  };
  

  return(
    <Box p={1}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box>
            <Typography className={classes.h6Header} variant="h6" style={{marginBottom: '14px'}}>Description</Typography>  
            <Typography variant="body2" style={{marginLeft: '32px'}} dangerouslySetInnerHTML={{__html: description}} />
          </Box>

          <Box mt={2}>
            <Typography className={classes.h6Header} variant="h6">Goals</Typography>  
            <Typography variant="body2" dangerouslySetInnerHTML={{__html: goals}} />
          </Box>

          <Box mt={2}>
            <Typography className={classes.h6Header} variant="h6">Inspiration</Typography>  
            <Typography variant="body1">
              <ul>
                {
                  example_links.split(',').map((link, idx) => (
                    <li key={idx} >
                      <a 
                        href={link} 
                        rel="noreferrer" 
                        target="_blank" 
                        style={{textDecoration: 'none'}}
                      >
                        {`Example ${idx+1}`}
                      </a>
                    </li>
                  ))
                }
              </ul>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Paper elevation={2} style={{padding: '16px', backgroundColor: '#F0F0F0'}}>
            {imageList && (<Box>
              <img src={convertS3Url(imageList[linkUrlIndex])} alt={`example-pic`} style={{width: "100%", height: '400px'}}/>
              <Button className={classes.leftArrow} variant="contained" onClick={() => setLinkUrlIndex(linkUrlIndex - 1 === -1 ? 3: linkUrlIndex - 1 % 4)}><ArrowBackIcon /></Button>
              <Button className={classes.rightArrow} variant="contained" onClick={() => setLinkUrlIndex((linkUrlIndex + 1) % 4)}><ArrowForwardIcon /></Button>
            </Box>)}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChallengePreview;