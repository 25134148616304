import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Challenge from './Challenge/Challenge';
import { Box, CircularProgress } from '@material-ui/core';
import ImageSelecter from './ImageSelecter/ImageSelecter';
import ImageInfo from './ImageInfo/ImageInfo';
import ImageLayout from './ImageLayout/ImageLayout';
import Post from './Review/PostReview';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const UploadStepper = props => {
  const { handleChange, handleSubmit, handleFileChange, data, file, fileURL, loading, error, getMetadata } = props;
  
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const getSteps = () => {
    return ['Challenge', 'Images', 'Info', 'Layout', 'Review'];
  };
  
  const getStepContent = step => {
    switch (step) {
      case 0:
        return <Challenge data={data} handleChange={handleChange}/>;
      case 1:
        return <ImageSelecter handleFileChange={handleFileChange} file={file}/>;
      case 2:
        return <ImageInfo data={data} handleChange={handleChange} fileURL={fileURL} getMetadata={getMetadata}/>;
      case 3:
        return <ImageLayout />;
      case 4:
        return <Post data={data} fileURL={fileURL}/>
      default:
        return 'Unknown step';
    }
  };

  const steps = getSteps();

  const isStepOptional = (step) => {
    return step === 0 || step === 3;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = <Typography variant="caption">Optional</Typography>;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
            <Box textAlign="center">
              <Typography variant="body1" color="error">{error}</Typography>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                Back
              </Button>
              {isStepOptional(activeStep) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSkip}
                  className={classes.button}
                >
                  Skip
                </Button>
              )}
              {activeStep !== steps.length - 1 && 
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    Next
                  </Button>
              }
              {activeStep === steps.length - 1 &&
                <Button type='submit' onClick={handleSubmit} variant="contained" color="primary">
                  Submit Post
                  {loading && <Box pt={1} pl={1}><CircularProgress color="secondary" size={20}/></Box>}
                </Button>
              }
            </Box>
          </div>
        )}
      </div>
    </div>
  );
}

export default UploadStepper;