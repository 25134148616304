import { Box, Typography } from '@material-ui/core';
import React from 'react';
import Axios from 'axios';
import { useHistory } from "react-router-dom";
import UploadStepper from '../UploadStepper/UploadStepper';
import { connect } from 'react-redux';

const UploadForm = props => {
  const history =  useHistory();

  const { user } = props;

  const [file, setFile] = React.useState(null);
  const [fileURL, setFileURL] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({team: null, challenge: null, intentions: "", setupNotes: "", caption: "", metadata: ""});

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setFileURL(URL.createObjectURL(event.target.files[0]));
  } 

  const handleChange = (event) => {
    setData({...data, [event.target.name]: event.target.value});
  } 

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const config = {
      headers: { 
        Authorization: `Bearer ${user.jwt_token}`,
        'Content-Type': 'multipart/form-data'
      }
    };
    let formData = new FormData();
    formData.append('file', file);
    formData.append('textData', JSON.stringify(data));
    formData.append('size', file.size);
    Axios.post('/upload', formData, config).then(resp => {
      setLoading(false);
      resp.data.msg = true 
        ? history.push("/home")
        : setError(resp.data.error);
    }).catch(resp => {
      setLoading(false);
      setError(resp.response.data.error)
    });
  }

  const getMetadata = e => {
    setLoading(true);
    const config = {
      headers: { 
        Authorization: `Bearer ${user.jwt_token}`,
        'Content-Type': 'multipart/form-data'
      }
    };
    let formData = new FormData();
    formData.append('file', file);
    Axios.post('/metadata', formData, config).then(resp => {
      setLoading(false);
      resp.data.msg = true 
        ? setData({...data, metadata: resp.data.metadata})
        : setData({...data, metadata: "No metadata from server"});
      }).catch(resp => {
        setLoading(false);
        setData({...data, metadata: "No metadata from server"});
    });
  }

  return(
    <Box p={3}>
      <Typography variant="h4">UPLOAD</Typography>
      <UploadStepper 
        handleChange={handleChange} 
        handleSubmit={handleSubmit} 
        handleFileChange={handleFileChange} 
        file={file} 
        fileURL={fileURL}
        data={data} 
        loading={loading}
        error={error}
        getMetadata={getMetadata}
      />
    </Box>
    

  );
};

const mapStateToProps = store => {
  return {
    user: store.user.currentUser
  }
};

export default connect(mapStateToProps)(UploadForm);