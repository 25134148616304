import { Box, Card, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(3),
    textAlign: 'center',
    width: '70%',
    margin: 'auto',

    '&:hover': {
      cursor: 'pointer'
    },
  },
}));


const ChallengesPage = props => {
  const classes = useStyles();
  const history = useHistory();

  const [cardHover1, setCardHover1] = useState(false);
  const [cardHover2, setCardHover2] = useState(false);
  const [cardHover3, setCardHover3] = useState(false);

  const handleClick = (level) => history.push(`/challenges/${level}`);

  // mock data
  const data = {
    copy: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."}

  return (
    <Box pl={4} pr={4} pt={2} >
      <Typography variant="h4">CHALLENGES</Typography>
      <Box mt={2}>
        <Typography variant="body1">{data.copy}</Typography>
      </Box>
      <br />
      <Divider />
      <Box mt={2} display="flex" justifyContent="space-evenly">
        <Box>
          <Card 
            elevation={cardHover1 ? 10 : 2} 
            className={classes.card} 
            onMouseEnter={() => setCardHover1(true)}
            onMouseLeave={() => setCardHover1(false)}
            onClick={() => handleClick("Beginner")}
          >
            <Typography variant="h5">Beginner</Typography>
            <Box mt={2} mb={4} pl={5} pr={5}>
              <Typography variant="body2">{data.copy}</Typography>
            </Box>
          </Card>
        </Box>
        <Box>
        <Card 
            elevation={cardHover2 ? 10 : 2} 
            className={classes.card} 
            onMouseEnter={() => setCardHover2(true)}
            onMouseLeave={() => setCardHover2(false)}
            onClick={() => handleClick("Intermediate")}
          >
            <Typography variant="h5">Intermediate</Typography>
            <Box mt={2} mb={4} pl={5} pr={5}>
              <Typography variant="body2">{data.copy}</Typography>
            </Box>
          </Card>
        </Box>
        <Box>
        <Card 
            elevation={cardHover3 ? 10 : 2} 
            className={classes.card} 
            onMouseEnter={() => setCardHover3(true)}
            onMouseLeave={() => setCardHover3(false)}
            onClick={() => handleClick("Advanced")}
          >
            <Typography variant="h5">Advanced</Typography>
            <Box mt={2} mb={4} pl={5} pr={5}>
              <Typography variant="body2">{data.copy}</Typography>
            </Box>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default ChallengesPage;