import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { clearCurrentUser } from '../../redux/user/userActions';

const LogoutPage = ({logoutUser}) => {
    const history = useHistory();

    logoutUser();
    history.push('/login');

    return null;
};

const mapDispatchToProps = dispatch => ({
    logoutUser: userObj => dispatch(clearCurrentUser(null)),
  });

export default connect(null, mapDispatchToProps)(LogoutPage);