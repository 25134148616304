import { Box, Button, CircularProgress, FormControl, Grid, makeStyles, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import { useHistory } from 'react-router';

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

const UserSettings = props => {
  const { user } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const history = useHistory();

  const initialProfileState = {fname: user.fname, lname: user.lname, username: user.username, password: "fakePassword", verifyPassword: "", privacy: "Public", pinned: 0}

  const [profileUpdates, setProfileUpdates] = React.useState(initialProfileState);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = event => {
    setProfileUpdates({...profileUpdates, [event.target.name]: event.target.value});
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();
    setLoading(true);
    const config = {
      headers: { 
        Authorization: `Bearer ${user.jwt_token}`,
        'Content-Type': 'multipart/form-data'
      }
    };
    let formData = new FormData();
    formData.append('data', JSON.stringify(profileUpdates));
    Axios.post('/user/settings', formData, config).then(resp => {
      setLoading(false);
      resp.data.msg = true 
        ? history.push("/home")
        : setError(resp.data.error);
    }).catch(resp => {
      console.log(resp.response);
      setLoading(false);
      setError(resp.response.data.error || resp.response.data.msg)
    });
  };

  return (
    <Box p={4}>
    <Typography variant="h2">My Account</Typography>
      <Grid container style={{marginTop: 32}}>
        <Grid item xs={2}>
          <Tabs
            orientation="vertical"
            value={value}
            onChange={handleTabChange}
            aria-label="Vertical tabs for account seetings"
            className={classes.tabs}
          >
            <Tab label="Profile" />
            <Tab label="Social" />
            <Tab label="Account Information" />
          </Tabs>
        </Grid>
        <Grid item xs={10}>
          <TabPanel value={value} index={0}>
            <Box pl={12}>
              <form noValidate autoComplete="off" onSubmit={handleSubmitForm}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField name="fname" label="First Name" value={profileUpdates.fname} onChange={handleInputChange} variant="outlined" fullWidth margin="dense" autoComplete={false}/>
                    <TextField name="username" label="Username" value={profileUpdates.username} onChange={handleInputChange} variant="outlined" fullWidth margin="dense" autoComplete={false}/>
                    <TextField disabled name="privacy" label="Privacy" value={profileUpdates.privacy} onChange={handleInputChange} variant="outlined" fullWidth margin="dense" autoComplete={false}/>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField name="lname" label="Last Name" value={profileUpdates.lname} onChange={handleInputChange} variant="outlined" fullWidth margin="dense" autoComplete={false}/>
                    <TextField disabled type="password" name="password" label="Password" value={profileUpdates.password} onChange={handleInputChange} variant="outlined" fullWidth margin="dense" autoComplete={false}/>
                    <TextField disabled name="pinned" label="Pinned Challenge" value={profileUpdates.pinned} onChange={handleInputChange} variant="outlined" fullWidth margin="dense" autoComplete={false}/>
                  </Grid>
                </Grid>
                <Box mt={2}>
                  {error && <Typography color="error" variant="body1">{error}</Typography>}
                </Box>
                <Box mt={2}>
                  <Button variant="contained" type="submit">
                    Submit Changes
                    {loading && <CircularProgress />}
                  </Button>
                </Box>
              </form>
              
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Box pl={12}>
              Coming Soon!
            </Box>
          </TabPanel>
          <TabPanel value={value} index={2}>
            Tab Three
          </TabPanel>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = store => {
  return {
    user: store.user.currentUser
  }
};

export default connect(mapStateToProps)(UserSettings);