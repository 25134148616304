import { Box, Button, Card, Grid, TextField } from '@material-ui/core';
import React from 'react';

const ImageInfo = ({data, handleChange, fileURL, getMetadata}) => {
    return (
      <Box p={4}>
        <Card style={{backgroundColor: '#F0F0F0'}}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Box p={2}>
                <TextField 
                  multiline 
                  rows={5}
                  style={{width: '100%'}}
                  name="intentions"  
                  variant="outlined"
                  placeholder="What was you intention with this shot?"
                  label="Intention"
                  color="primary"
                  value={data.intentions}
                  onChange={handleChange}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box p={2}>
                <TextField 
                  multiline 
                  rows={5} 
                  style={{width: '100%'}}
                  name="setupNotes"
                  label="Setup Notes"
                  variant="outlined"
                  placeholder="Describe your setup for the shot here"
                  color="primary"
                  value={data.setupNotes}
                  onChange={handleChange}
                />
              </Box>
            </Grid>
          </Grid>
        </Card>
        <br />
        <Card style={{backgroundColor: '#F0F0F0'}}>
          <Grid container>
            <Grid item xs={6} >
              <Box p={2}>
                <img src={fileURL} alt="your upload" style={{width: "100%", height: "100%"}}/>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box p={2}>
                <TextField 
                  multiline 
                  rows={3} 
                  style={{width: '100%'}}
                  name="caption"
                  label="Caption"
                  variant="outlined"
                  placeholder="Caption this image :)"
                  color="primary"
                  value={data.caption}
                  onChange={handleChange}
                />
                <br />
                <br />
                <Box display="flex">
                  <TextField 
                    multiline 
                    rows={5} 
                    style={{width: '50%'}}
                    name="metadata"
                    label="Metadata"
                    variant="outlined"
                    placeholder="Shutter speed? ISO? Aspect ratio?"
                    color="primary"
                    value={data.metadata}
                    onChange={handleChange}
                  />
                  <Box pl={4} pt={5}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={getMetadata}
                      >
                      Get Metadata
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Box>
    );
};

export default ImageInfo;