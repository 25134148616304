import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Paper } from '@material-ui/core';
import Axios from 'axios';
import { connect } from 'react-redux';
import { setCurrentUser } from '../../redux/user/userActions';
import { useHistory } from 'react-router';
import CircularProgress from '@material-ui/core/CircularProgress';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="/">
        Capture
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

 const Login = props => {
  const classes = useStyles();
  const { loginUser } = props;
  const history = useHistory();

  const [data, setData] = useState({email: '', password: '', remember: false})
  const [ isLoading, setLoading ] = useState(false);
  const [ error, setError ] = useState(null);

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    Axios
      .post('/login', data)
      .then(resp => {
        loginUser({jwt_token: resp.data.user_data.jwt_token, refresh_token: resp.data.user_data.refresh_token, username: resp.data.user_data.username, email: resp.data.user_data.email, fname: resp.data.user_data.fname, lname: resp.data.user_data.lname});
        setLoading(false);
        history.push('/home');
      })
      .catch(({ response }) => {
        response.status === 401 && setError("Invalid email or password :(");
        response.status >= 500 && setError("Server error, try again in a minute");
        response.status > 401 && response.status < 500 && setError("Something weird happened...");
        setLoading(false);
      });
  }

  const toggleRemember = () => {
    setData({...data, remember: !data.remember});
  }

  const handleChange = (e) => {
    setError(null);
    setData({...data, [e.target.name]: e.target.value});
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
        <Paper elevation={2}>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                value={data.email}
                name="email"
                autoComplete="email"
                autoFocus
                onChange={handleChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                value={data.password}
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleChange}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
                onChange={toggleRemember}
              />
              { error && 
                <Box pt={1} style={{textAlign: 'center'}}>
                  <Typography variant="body2" color="error">
                    {error}
                  </Typography> 
                </Box>  
              }
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
                { isLoading && <CircularProgress /> }
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/register" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Paper>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

const mapDispatchToProps = dispatch => ({
  loginUser: userObj => dispatch(setCurrentUser(userObj)),
});

export default connect(null, mapDispatchToProps)(Login);
