import React from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { Box, Grid, Link } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Gravatar from "react-gravatar";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import MyPosts from "../MyPosts/MyPosts";

const drawerWidth = 220;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#434343",
    // opacity: '0.75'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: "rotate(0deg)",
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: "rotate(180deg)",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: "#434343",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
    backgroundColor: "#434343",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  grow: {
    flexGrow: 1,
  },
});

const HomePage = (props) => {
  const { classes, user } = props;
  const [state, setState] = React.useState({ open: false });
  const history = useHistory();

  if (!user) {
    history.push("/login");
    return null;
  }

  const handleDrawerOpen = () => {
    setState({ ...state, open: !state.open });
  };

  // MOCK DATA
  const userMock = {
    firstname: "Steve",
    lastname: "Robertson",
    email: "test@gamil.com",
    followers: 1921,
    following: 873,
    bio:
      // eslint-disable-next-line no-multi-str
      "Sed ut perspiciatis unde omnis iste natus error \
          sit voluptatem accusantium doloremque laudantium, \
          totam rem aperiam, eaque ipsa quae ab illo inventore \
          veritatis et quasi architecto beatae vitae dicta sunt \
          explicabo. Nemo enim ipsam voluptatem quia voluptas \
          sit aspernatur aut odit aut fugit, sed quia \
          consequuntur magni dolores eos qui ratione voluptatem \
          sequi nesciunt. Neque",
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar disableGutters={true}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerOpen}
            className={classes.menuButton}
          >
            <MenuIcon
              classes={{
                root: state.open
                  ? classes.menuButtonIconOpen
                  : classes.menuButtonIconClosed,
              }}
            />
          </IconButton>
          <Typography variant="h4">CAPTURE</Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: state.open,
          [classes.drawerClose]: state.open,
        })}
        classes={{
          paper: classNames({
            [classes.drawerOpen]: state.open,
            [classes.drawerClose]: !state.open,
          }),
        }}
        open={state.open}
      >
        <div className={classes.toolbar} />
        <List>
          {["Home", "Teams", "Feed", "Account", "Upload"].map((text, index) => (
            <Box key={`Box-${index}`}>
              <ListItem
                button
                key={text}
                component={Link}
                href="/upload"
                style={{ textDecoration: "none" }}
              >
                <ListItemIcon style={{ color: "white" }}>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} style={{ color: "white" }} />
              </ListItem>
              <Divider />
            </Box>
          ))}
          <br />
          <br />
          <br />
          <br />
          <br />
          <Box>
            <ListItem
              button
              component={Link}
              href="/logout"
              style={{ textDecoration: "none" }}
            >
              <ListItemIcon style={{ color: "white" }}>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary={"Logout"} style={{ color: "white" }} />
            </ListItem>
            <Divider />
          </Box>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={4}>
          <Grid item container xs={3} style={{ backgroundColor: "#F0F0F0" }}>
            <Grid item xs={12}>
              <Box textAlign="center">
                <Gravatar
                  email={user.email}
                  size={128}
                  rating="pg"
                  default="identicon"
                  className="CustomAvatar-image"
                  style={{ borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography variant="h5">{`${user.fname} ${user.lname}`}</Typography>
              </Box>
              <Box
                mt={1}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography variant="body1">Followers</Typography>
                <Typography variant="body1">Following</Typography>
              </Box>
              <Box
                mt={0.25}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography variant="body1">{userMock.followers}</Typography>
                <Typography variant="body1">{userMock.following}</Typography>
              </Box>
              <hr />
              <Box style={{ textAlign: "left" }}>
                <Typography variant="body1">Bio:</Typography>
                <Typography variant="caption">{userMock.bio}</Typography>
              </Box>
              <hr />
              <Box>
                <Typography variant="body1">Projects:</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item container xs={9} style={{ justifyContent: "center" }}>
            <Box textAlign="center">
              <Typography variant="h4">My Posts</Typography>
              <MyPosts />
            </Box>
          </Grid>
        </Grid>
      </main>
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    user: store.user.currentUser,
  };
};
export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(HomePage)
);
